.issue {
    display: flex;
    flex-direction: column;
    padding: 45px;

    & .issue-wrapper {
        background-color: #fff;
        box-shadow: 0 4px 16px 4px rgba(0, 0, 0, 0.04);
        border-radius: 8px;

        & .issue-textarea {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            resize: none;
            padding: 12px;
            min-height: 128px;
            width: 100%;
            font-size: 16px;
            color: inherit;
            border: none;
            border-radius: 6px;
            background-color: #F4F4F4;

            & .answer-info {
                color: #929292;
                text-overflow: ellipsis;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
            }

            &:focus {
                outline: none;
            }
        }

        & .issue-header {
            padding: 24px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            & .issue-title {
                color: #000;
                font-size: 20px;
                font-weight: 600;
            }

            & .issue-status-span {
                padding: 6px;
                border-radius: 4px;
                color: #fff;

            }

            .new {
                background-color: #6AA5EA;
            }

            .in-progress {
                background-color: #F6A532;
            }

            .closed {
                background-color: #6AAC70;
            }

            & .issue-status-select {
                height: 40px;
                padding: 8px 20px;
                border-radius: 4px;
                background-color: #F4F4F4;
                border: none;
                background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
                background-repeat: no-repeat;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                -webkit-appearance: none;
                -moz-appearance: none;
                background-image:
                        linear-gradient(45deg, transparent 50%, black 50%),
                        linear-gradient(135deg, black 50%, transparent 50%);
                background-position:
                        calc(110% - 20px) calc(1em + 4px),
                        calc(110% - 15px) calc(1em + 4px),
                        100% 0;

                &:focus {
                    outline: none;
                    background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
                    background-repeat: no-repeat;
                    background-image:
                            linear-gradient(45deg, black 50%, transparent 50%),
                            linear-gradient(135deg, transparent 50%, black 50%);
                    background-position:
                            calc(110% - 15px) 1.1em,
                            calc(110% - 20px) 1.1em,
                            100% 0;
                    border-color: grey;
                    outline: 0;
                }

                &:disabled {
                    background-size: 0 !important;
                }
            }
        }

        & .issue-body {
            padding: 24px;
            font-size: 16px;
            border-bottom: 1px solid #E7EAEE;

            & .issue-section {
                border-bottom: 1px solid #E7EAEE;
                display: flex;
                justify-content: space-between;
                padding-bottom: 16px;

                & .issue-info {
                    position: relative;

                    & .is-comment {
                        position: absolute;
                        top: -30px;
                        left: 8px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        background-color: #FCFCFC;
                        border-radius: 100px;
                        padding: 8px;
                        font-size: 14px;
                        max-width: 138px;
                        box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.10);
                    }


                    & .issue-username {
                        color: #000;
                        font-size: 20px;
                        font-weight: 600;
                        padding-bottom: 8px;
                    }

                    & .issue-info-item {
                        padding-top: 8px;

                        & .issue-info-type {
                            color: #929292;
                        }

                        & .issue-info-name {
                            color: #000;
                        }
                    }

                    & .comment-helper {
                        position: relative;
                    }
                }
            }
            & .user {
                display: block;
            }

            .text-content {
                display: block;
                padding-top: 16px;
                border-bottom: none;

                & .issue-text-title {
                    color: #000;
                    font-weight: 400;
                    padding-bottom: 8px;
                    opacity: 0.4;
                }

                & .issue-text-content {
                    color: #000;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                }
            }

            & .attached-files {
                border-top: 1px solid #E7EAEE;
                padding-top: 16px;

                & .file-link {
                    text-decoration: none;
                    color: inherit;
                    & .file-wrapper {
                        cursor: pointer;
                        background-color: #F4F4F4;;
                        border-radius: 6px;
                        border: 1px solid #F4F4F4;
                        display: flex;
                        max-width: 360px;
                        padding: 10px 14px;
                        gap: 16px;
                        align-items: flex-start;
                        margin-bottom: 16px;

                        & .file-info {
                            overflow: hidden;
                            text-overflow: ellipsis;

                            & .file-name {
                                color: #000;
                                font-size: 14px;
                                font-weight: 600;
                                line-height: 20px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }

                            & .file-size {
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 20px;
                                opacity: .4;
                            }
                        }
                    }
                }

            }
        }
         & .issue-footer {
             background-color: #fff;
             padding: 16px 24px;

             & .issue-button {
                 margin-top: 16px;
                 text-transform: none;
                 background-color: #D2AD66;
                 color: #fff;
             }
         }
    }
}

.relative {
    position: relative;
}
